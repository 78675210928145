import React, {useEffect, useState} from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput
}
from 'mdb-react-ui-kit';
import '../pages/login.css';
import logo from '../assets/lfc.png';
import axios from 'axios';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loginStatus, setLoginStatus] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const navigate = useNavigate()
    function handleLogin() {
        axios.post('https://vps.cjncode.co.za/login',
            {
                username: email,
                password: password
            }
        ).then((response) => {
            if(response.data.message){
                setShowAlert(true)
                setLoginStatus(response.data.message)
            }else{
                navigate("/home")
            }
        })
    }
  return (
    <MDBContainer className="my-5">

      <MDBCard className="my-5" style={{height: '90vh'}}>
        <MDBRow className='g-0'>

         
          <MDBCol md='6'>
            <MDBCardBody className='d-flex flex-column'>
            
              <div className='d-flex flex-row mt-2' style={{alignContent: 'center', alignItems: 'center'}}>
                <img src={logo} style={{width: '60%', alignSelf: 'center', marginLeft: '18%'}}/>
              </div> 
              <div style={{marginTop: -100}}>
              {showAlert ?  <Alert severity="error">{loginStatus}</Alert> : <></> }
              
              <h5 className="fw-normal my-4 pb-3" style={{letterSpacing: '1px'}}>Welcome! Let's sign you in...</h5>
                
                <MDBInput wrapperClass='mb-4' label='Email address' id='formControlLg' type='email' size="lg" onChange={(e) => setEmail(e.target.value)}/>
                <MDBInput wrapperClass='mb-4' label='Password' id='formControlLg' type='password' size="lg" onChange={(e) => setPassword(e.target.value)}/>

              <MDBBtn className="mb-4 px-5" color='dark' size='lg' onClick={handleLogin}>Login</MDBBtn>
              <p className="mb-5 pb-lg-2" style={{color: '#393f81'}}><a href="#!" style={{color: '#393f81'}}>Forgot Password?</a></p>

              <div className='d-flex flex-row justify-content-start' style={{marginTop: -50}}>
                <a href="#!" className="small text-muted me-1">Terms Of Use | </a>
                <a href="#!" className="small text-muted">Privacy policy</a>
              </div>
              </div>
            </MDBCardBody>
          </MDBCol>
          <MDBCol md='6'>
            <MDBCardImage src='https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp' alt="login form" className='rounded-end w-100' style={{height: '90vh'}}/>
          </MDBCol>

        </MDBRow>
      </MDBCard>

    </MDBContainer>
  );
}

export default Login;
