import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import { MDBBtn } from 'mdb-react-ui-kit';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from '../assets/lfc.png'
import { DataGrid } from '@mui/x-data-grid';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Alert } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Woman2Icon from '@mui/icons-material/Woman2';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import GroupIcon from '@mui/icons-material/Group';

import { useNavigate } from 'react-router-dom';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );
  
  const transportReq = [
    {
      value: 'I Require Transport',
      label: 'I Require Transport',
    },
    {
      value: 'I Do Not Require Transport',
      label: 'I Do Not Require Transport',
    },
  ];

  const campusData = [
    {
      value: 'Tongaat',
      label: 'Tongaat',
    },
    {
      value: 'Verulam',
      label: 'Verulam',
    },
    {
        value: 'Stanger',
        label: 'Stanger',
      },
      {
        value: 'Mandeni',
        label: 'Mandeni',
      },
      {
        value: 'Edenvale',
        label: 'Edenvale',
      },
      {
        value: 'Visitor',
        label: 'Visitor',
      },
  ];
const drawerWidth = 240;


function Register(props) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const nav = useNavigate()
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')
  const [number, setNumber] = useState('')
  const [transport, setTransport] = useState('')
  const [campus, setCampus] = useState('')
  const [registerStatus, setRegisterStatus] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  function handleRegister() {
    axios.post('https://vps.cjncode.co.za/registerLadies',
        {
            name: name,
            surname: surname,
            address: address,
            email: email,
            number: number,
            transport: transport,
            campus: campus
        }
    ).then((response) => {
        if(response.data.message){
            setShowAlert(true)
            setRegisterStatus(response.data.message)
        }else{
            setShowSuccess(true)
        }
    })
  }




    
 

  const drawer = (
    <div>
      <Toolbar />
      <img src={logo} style={{width: '100%', marginTop: -100}}/>
      <Divider style={{marginTop: -60}}/>
      <List>
          <ListItem disablePadding onClick={()=> nav('/home')}>
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon/>
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding >
            <ListItemButton onClick={()=> nav('/ladies')}>
              <ListItemIcon>
                <Woman2Icon/>
              </ListItemIcon>
              <ListItemText>Ladies</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => nav('/register')}>
              <ListItemIcon>
                <ContentPasteIcon/>
              </ListItemIcon>
              <ListItemText>Register</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => nav('/users')}>
              <ListItemIcon>
                <GroupIcon/>
              </ListItemIcon>
              <ListItemText>Users</ListItemText>
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{backgroundColor: '#ff9db2'}}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Register
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <div style={{ width: '100%'}}>
          <img src={logo} width={'20%'} style={{alignSelf: 'center', marginLeft: '40%'}}/>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '2%'}}>
          <TextField fullWidth label="Name" id="fullWidth" color='secondary' focused style={{width: '49%'}} onChange={(e) => setName(e.target.value)}/>
          <TextField fullWidth label="Surname" id="fullWidth" color='secondary' focused style={{width: '49%'}} onChange={(e) => setSurname(e.target.value)}/>
          </div>
          <TextField fullWidth label="Address" id="fullWidth" color='secondary' focused onChange={(e) => setAddress(e.target.value)}/>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',  marginBottom: '2%', marginTop: '2%'}}>
          <TextField fullWidth label="Email Address" id="fullWidth" color='secondary' focused style={{width: '49%'}} onChange={(e) => setEmail(e.target.value)}/>
          <TextField fullWidth label="Number" id="fullWidth" color='secondary' focused style={{width: '49%'}} onChange={(e) => setNumber(e.target.value)}/>
          </div>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <TextField
          fullWidth
          id="fullWidth"
          select
          label="Transport"
          defaultValue="I Require Tranport"
          helperText="Select Transport Option"
          color='secondary'
          focused
          style={{width: '49%'}}
          onChange={(e) => setTransport(e.target.value)}
        >
          {transportReq.map((option) => (
            <MenuItem key={option.value} value={option.value} color='secondary'>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          id="fullWidth"
          select
          label="Campus"
          defaultValue="Tongaat"
          helperText="Select Your Campus"
          color='secondary'
          focused
          style={{width: '49%'}}
          onChange={(e) => setCampus(e.target.value)}
        >
          {campusData.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
          </div>
          <MDBBtn className="mb-4 px-5" color='secondary' size='lg' fullWidth style={{width:'50%', marginLeft: '25%', marginTop: '2%'}} onClick={handleRegister}>Register</MDBBtn>
          {showAlert ?  <Alert severity="error">{registerStatus}</Alert> : <></> }
          {showSuccess ?  <Alert severity="success">Successfully Registered! Refresh the page to continue capturing.</Alert> : <></> }
        </div>
      </Box>
    </Box>
  );
}

Register.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default Register;