import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo from '../assets/lfc.png'

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import axios from 'axios';

import { BarChart } from '@mui/x-charts/BarChart';

import { useNavigate } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import Woman2Icon from '@mui/icons-material/Woman2';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import GroupIcon from '@mui/icons-material/Group';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
      •
    </Box>
  );
  
const drawerWidth = 240;

function Home(props) {
  const [ladies, setLadies] = useState([])
  const [users, setUsers] = useState([])
  const [tongaat, setTongaat] = useState([])
  const [verulam, setVerulam] = useState([])
  const [stanger, setStanger] = useState([])
  const [mandeni, setMandeni] = useState([])
  const [edenvale, setEdenvale] = useState([])
  const [visitor, setVisitor] = useState([])
  const [transport, setTransport] = useState([])
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const nav = useNavigate()

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  useEffect(() => {
    axios.get('https://vps.cjncode.co.za/getLadies').then((response) => {
        setLadies(response.data)
        console.log(setLadies.length)
        
    })

    axios.get('https://vps.cjncode.co.za/getUsers').then((response) => {
        setUsers(response.data)
    })

    axios.get('https://vps.cjncode.co.za/getTransport').then((response) => {
        setTransport(response.data)
    })

    axios.get('https://vps.cjncode.co.za/getTongaat').then((response) => {
        setTongaat(response.data)
    })

    axios.get('https://vps.cjncode.co.za/getVerulam').then((response) => {
        setVerulam(response.data)
    })

    axios.get('https://vps.cjncode.co.za/getStanger').then((response) => {
        setStanger(response.data)
    })

    axios.get('https://vps.cjncode.co.za/getMandeni').then((response) => {
        setMandeni(response.data)
    })

    axios.get('https://vps.cjncode.co.za/getEdenvale').then((response) => {
        setEdenvale(response.data)
    })

    axios.get('https://vps.cjncode.co.za/getVisitors').then((response) => {
        setVisitor(response.data)
    })
  },[])

    
 

  const drawer = (
    <div>
      <Toolbar />
      <img src={logo} style={{width: '100%', marginTop: -100}}/>
      <Divider style={{marginTop: -60}}/>
      <List>
          <ListItem disablePadding onClick={()=> nav('/home')}>
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon/>
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding >
            <ListItemButton onClick={()=> nav('/ladies')}>
              <ListItemIcon>
                <Woman2Icon/>
              </ListItemIcon>
              <ListItemText>Ladies</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => nav('/register')}>
              <ListItemIcon>
                <ContentPasteIcon/>
              </ListItemIcon>
              <ListItemText>Register</ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton onClick={() => nav('/users')}>
              <ListItemIcon >
                <GroupIcon/>
              </ListItemIcon>
              <ListItemText>Users</ListItemText>
            </ListItemButton>
          </ListItem>
      </List>
      <Divider />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
        style={{backgroundColor: '#ff9db2'}}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <div style={{width: '100%', display: 'flex' , flexDirection: 'row', justifyContent: 'space-around'}}>
        <Card sx={{ minWidth: 275, backgroundColor: '#ff9db2'}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='white' gutterBottom>
          Ladies Analytics
        </Typography>
        <Typography variant="h2" component="div" color="white">
          {ladies.length}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="white">
          Registered Ladies
        </Typography>
        <Typography variant="body2" color="white">
          Surrender Ladies Conference 2024.
          <br />
       
        </Typography>
      </CardContent>
      <CardActions style={{color: 'white'}}>
        <Button size="small" style={{color: 'white'}}>See More</Button>
      </CardActions>
    </Card>
   
    <Card sx={{ minWidth: 275, backgroundColor: '#ff9db2'}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='white' gutterBottom>
          Administrator Analytics
        </Typography>
        <Typography variant="h2" component="div" color="white">
         {users.length}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="white">
          Registered Administrators
        </Typography>
        <Typography variant="body2" color="white">
          Surrender Ladies Conference 2024.
          <br />
       
        </Typography>
      </CardContent>
      <CardActions style={{color: 'white'}}>
        <Button size="small" style={{color: 'white'}}>See More</Button>
      </CardActions>
    </Card>
    <Card sx={{ minWidth: 275, backgroundColor: '#ff9db2'}}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color='white' gutterBottom>
          Transport Analytics
        </Typography>
        <Typography variant="h2" component="div" color="white">
          {transport.length}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="white">
          Ladies Require Transport
        </Typography>
        <Typography variant="body2" color="white">
          Surrender Ladies Conference 2024.
          <br />
       
        </Typography>
      </CardContent>
      <CardActions style={{color: 'white'}}>
        <Button size="small" style={{color: 'white'}}>SEE MORE</Button>
      </CardActions>
    </Card>
    </div>
    <BarChart
      xAxis={[
        {
          id: 'barCategories',
          data: ['Tongaat', 'Verulam', 'Stanger', 'Mandeni', 'Edenvale', 'Visitors'],
          scaleType: 'band',
          colorMap: {
            type: 'ordinal',
            colors: ['pink', '#a8ddb5', '#7bccc4', '#4eb3d3', '#2b8cbe', '#08589e']
          }
        },
      ]}
      series={[
        {
          data: [tongaat.length, verulam.length, stanger.length, mandeni.length, edenvale.length, visitor.length],
        },
      ]}
      width={1000}
      height={500}
      
      style={{marginLeft: '5%', marginTop: '8%', marginTop: -20}}
    />
      </Box>
    </Box>
  );
}

Home.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default Home;